body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #282c34; */
  background-color: #4c566a;
  color: #eceff4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.appContainer {
     display: flex;
     flex-direction: column;
     min-height: 100vh
}

.header {
    width: 100%;
    padding: 10px 20px;
    background: #2e3440;
}

.header__link {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}

.header__brand {
    font-size: 2.5em;
    font-weight: bold;
}

.snipContent {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 15px 4%;
}

.snipContent__main {
    flex: 100%;
}

.snipContent__sidebar {
    flex: 0 0 100%;
    border: 2px solid #d8dee9;
    border-radius: 10px;
}

.snipContent__sidebar__title {
    margin: 0 auto;
    font-size: 1em;
    font-weight: bold;
    text-align: center;
    padding-top: 5px;
}

.footer {
    display: flex;
    justify-content: center;
    background: #2e3440;
    margin-top: auto;
}

.footer a {
    color: #eceff4;
}

.snippetList__item {
    padding: 10px;
    font-size: 0.8em;
}

.snippetList__item__date {
    font-style: italic;
}

.snippetList__item a {
    color: inherit;
}

@media screen and (min-width: 1160px) {
    .snipContent__main {
        flex: 0 0 77%;
    }

    .snipContent__sidebar {
        flex: 0 0 22%;
    }
}

