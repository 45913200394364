.banner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.banner__message {
    color: black;
    font-weight: bold;
}

.banner__close {
    border: none;
    background: none;
    cursor: pointer;
}

.banner__close:hover {
    color: white;
}

@media only screen and (min-width: 1160px) {
    .banner {
        padding: 10px 25%;
    }
}
