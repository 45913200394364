.editor {
    /* min-width: 960px; */
    width: 100%;
}

.editor__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 10px 0;
}

.editor__titleInput {
    width: 100%;
    border-radius: 5px;
}

.editor__bottomBar {
    padding: 10px 0;
}

.editor__editKey {
    padding: 8px 0;
}

.editor__editKeyInput {
    width: 100%;
}

.editor__submitButton {
    width: 100%;
    height: 50px;
    padding: 10px;
    margin-right: 10px;
    text-decoration: none;
    font-weight: bold;
    font-family: inherit;
    color: #3b4252;
    background: #88c0d0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: 220ms all ease-in-out;
}
.editor__submitButton:hover,
.editor__submitButton:active,
.editor__submitButton:focus {
    outline: ridge;
}

.red {
    background: #bf616a;
}

@media screen and (min-width: 1160px) {

    .editor__bottomBar {
        padding: 10px 0 0 0;
    }

    .editor__submitButton {
        width: auto;
        height: auto;
    }

}
