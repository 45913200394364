.viewMDSnippet {
    max-width: 100%;
    padding: 20px;
}

.mdTitle {
    font-size: 2.5em;
}

/* .wmde-markdown > blockquote { */
/*     color: #d8dee9; */
/* } */

.viewMD pre {
    background-color: #3b4252;
}

.viewMD pre code {
    text-shadow: 1px 1px #000;
}

.viewMD hr {
    border: 2px solid #eee;
}


@media only screen and (min-width: 1160px) {
    .viewMDSnippet {
        width: 50%;
        margin: 0 auto;
        padding: 20px 0;
    }
}
